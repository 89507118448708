export default function categoryReducer(
  state = {
    count: 0,
    currentCategory: null,
    categories: [],
    fetching: false,
    fetched: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case "SET_CURRENT_CATEGORY": {
      return {
        ...state,
        currentCategory: action.category
      };
    }

    case "GET_CATEGORIES_START":
    case "GET_CATEGORY_START": {
      return {
        ...state,
        currentCategory: null,
        categories: [],
        count: 0,
        fetching: true,
        fetched: false,
        error: null
      };
    }
    case "GET_CATEGORIES_FULFILLED": {
      return {
        ...state,
        currentCategory: null,
        categories: action.categories,
        count: action.count,
        fetching: false,
        fetched: true,
        error: null
      };
    }

    case "GET_CATEGORY_FULFILLED": {
      return {
        ...state,
        currentCategory: action.category,
        fetching: false,
        fetched: true,
        error: null
      };
    }
    case "GET_CATEGORIES_REJECTED":
    case "GET_CATEGORY_REJECTED": {
      return {
        ...state,
        currentCategory: null,
        categories: [],
        count: 0,
        fetching: false,
        fetched: true,
        error: action.error
      };
    }

    /*
     **  SAVING CATEGORY
     */

    case "SAVE_CATEGORY_START": {
      return {
        ...state,
        currentCategory: null,
        fetching: true,
        fetched: false
      };
    }
    case "SAVE_CATEGORY_FULFILLED": {
      return {
        ...state,
        currentCategory: null,
        fetching: false,
        fetched: true
      };
    }
    case "SAVE_CATEGORY_REJECTED": {
      return {
        ...state,
        currentCategory: null,
        fetching: false,
        fetched: true,
        error: action.error
      };
    }

    /*
     **  UPDATING CATEGORY
     */

    case "UPDATE_CATEGORY_START": {
      return {
        ...state,
        currentCategory: null,
        fetching: true,
        fetched: false
      };
    }
    case "UPDATE_CATEGORY_FULFILLED": {
      return {
        ...state,
        currentCategory: null,
        fetching: false,
        fetched: true
      };
    }
    case "UPDATE_CATEGORY_REJECTED": {
      return {
        ...state,
        currentCategory: null,
        fetching: false,
        fetched: true,
        error: action.error
      };
    }

    /*
     **  REMOVING CATEGORY
     */

    case "REMOVE_CATEGORY_START": {
      return {
        ...state,
        currentCategory: null,
        fetching: true,
        fetched: false
      };
    }
    case "REMOVE_CATEGORY_FULFILLED": {
      return {
        ...state,
        currentCategory: null,
        fetching: false,
        fetched: true
      };
    }
    case "REMOVE_CATEGORY_REJECTED": {
      return {
        ...state,
        currentCategory: null,
        fetching: false,
        fetched: true,
        error: action.error
      };
    }

    default:
      return state;
  }
}
