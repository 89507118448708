export default function headerReducer(
  state = {
    dropdownActive: false
  },
  action
) {
  switch (action.type) {
    case "SET_DROPDOWN_ACTIVE": {
      return {
        ...state,
        dropdownActive: action.active
      };
    }
    default:
      return state;
  }
}
