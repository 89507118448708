import ApiService from "../../utils/apiService";
import { toastr } from "react-redux-toastr";
import localization from "../../utils/localization";

export const Types = {
  GET_FILES_COUNT_START: "priceLists/getFilesCountStart",
  GET_FILES_COUNT_FULFILLED: "priceLists/getFilesCountFulFilled",
  GET_FILES_COUNT_REJECTED: "priceLists/getFilesCountRejected",
  GET_FILES_START: "priceLists/getFilesStart",
  GET_FILES_FULFILLED: "priceLists/getFilesFulfilled",
  GET_FILES_REJECTED: "priceLists/getFilesRejected",
  CREATE_FILE_START: "priceLists/createFileStart",
  CREATE_FILE_FULFILLED: "priceLists/createFileFulfilled",
  CREATE_FILE_REJECTED: "priceLists/createFileRejected",
  UPDATE_FILE_START: "priceLists/updateFileStart",
  UPDATE_FILE_FULFILLED: "priceLists/updateFileFulfilled",
  UPDATE_FILE_REJECTED: "priceLists/updateFileRejected",
  DELETE_FILE_START: "priceLists/deleteFileStart",
  DELETE_FILE_FULFILLED: "priceLists/deleteFileFulfilled",
  DELETE_FILE_REJECTED: "priceLists/deleteFileRejected",
};

export function getFilesCount(searchText = "") {
  return function (dispatch) {
    dispatch({ type: Types.GET_FILES_COUNT_START });

    ApiService.get(`price-lists/count?q=${searchText}`)
      .then((response) => {
        dispatch({
          type: Types.GET_FILES_COUNT_FULFILLED,
          count: response.data.count,
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.GET_FILES_COUNT_REJECTED,
          count: 0,
        });
      });
  };
}

export function getFiles(query = "", limit = null) {
  return function (dispatch) {
    dispatch({ type: Types.GET_FILES_START });

    var params = query;
    let limitParam =
      (params === "" ? "?" : "&") + "limit=" + (limit ? limit : 20);

    ApiService.get(`price-lists${query}${limitParam}`)
      .then((response) => {
        dispatch({
          type: Types.GET_FILES_FULFILLED,
          files: response.data.files,
        });
      })
      .catch((error) => {
        dispatch({
          type: Types.GET_FILES_REJECTED,
          error: error,
        });

        if (error) {
          toastr.error(localization.toast.files.errorReading, error.message);
        }
      });
  };
}

export function createFile(file) {
  return function (dispatch) {
    dispatch({ type: Types.CREATE_FILE_START });

    ApiService.post("price-lists", file)
      .then((response) => {
        dispatch({ type: Types.CREATE_FILE_FULFILLED });
        dispatch(getFiles());
        toastr.success(
          localization.toast.success,
          localization.toast.files.successCreated
        );
      })
      .catch((error) => {
        dispatch({ type: Types.CREATE_FILE_REJECTED, error: error });
        if (error) {
          if (error.response) {
            if (!error.response.data.success) {
              toastr.error(
                localization.toast.files.errorCreating,
                error.response.data.error
              );
            }
          } else {
            toastr.error(localization.toast.files.errorCreating, error.message);
          }
        }
      });
  };
}

export function updateFile(file) {
  return function (dispatch) {
    dispatch({ type: Types.UPDATE_FILE_START });

    ApiService.patch(`price-lists/${file._id}`, file)
      .then((response) => {
        dispatch(getFiles());
        dispatch({ type: Types.UPDATE_FILE_FULFILLED });
        toastr.success(
          localization.toast.success,
          localization.toast.files.successUpdated
        );
      })
      .catch((error) => {
        dispatch({ type: Types.UPDATE_FILE_REJECTED, error: error });
        toastr.error(localization.toast.files.errorUpdating, error.message);
      });
  };
}

export function deleteFile(fileId) {
  return function (dispatch) {
    dispatch({ type: Types.DELETE_FILE_START });

    ApiService.delete(`price-lists/${fileId}`)
      .then((response) => {
        dispatch(getFiles());
        dispatch({ type: Types.DELETE_FILE_FULFILLED });

        toastr.success(
          localization.toast.success,
          localization.toast.files.successRemoved
        );
      })
      .catch((error) => {
        dispatch({
          type: Types.DELETE_FILE_REJECTED,
          error: error,
        });

        toastr.error(localization.toast.files.errorRemoving, error.message);
      });
  };
}
