const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  featured: [],
  feature: null
};

const featuredReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_FEATURED_START":
      return {
        ...state,
        fetching: true,
        fetched: false,
        featured: [],
        feature: null
      };

    case "GET_FEATURED_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        featured: action.featured
      };

    case "GET_FEATURED_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false,
        featured: []
      };

    case "GET_FEATURE_START":
      return {
        ...state,
        fetching: true,
        fetched: false,
        feature: null
      };

    case "GET_FEATURE_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        feature: action.featured
      };

    case "GET_FEATURE_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false,
        feature: null
      };

    case "CREATE_FEATURED_START":
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case "CREATE_FEATURED_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true
      };

    case "CREATE_FEATURED_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false
      };

    case "DELETE_FEATURED_START":
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case "DELETE_FEATURED_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true
      };

    case "DELETE_FEATURED_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false
      };

    case "RESET_FEATURED":
      return {
        ...state,
        feature: null
      };

    default:
      return state;
  }
};

export default featuredReducer;
