export default function shippingReducer(
  state = {
    shippings: [],
    fetching: false,
    fetched: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case "GET_SHIPPINGS_START": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        shippings: []
      };
    }

    case "GET_SHIPPINGS_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        shippings: action.shippings
      };
    }

    case "GET_SHIPPINGS_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        shippings: [],
        error: action.error
      };
    }

    default:
      return state;
  }
}
