import jwtDecode from "jwt-decode";
import moment from "moment";
import qs from "query-string";
import "jspdf-autotable";
import "../assets/fonts/Roboto-Regular-normal";
import "../assets/fonts/Roboto-Medium-normal";

/*
 ** URL VALIDATION
 */

export function isUrl(value) {
  const regExpr = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:(localhost))(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regExpr.test(value);
}

/*
 ** NUMBER MANIPULATIONS
 */

export function roundNumber(value) {
  return Math.round((value + 0.00001) * 100) / 100;
}

/*
 ** GENERATING AND PARSING URL QUERY
 */

// PRODUCTS

export function generateProductsQuery(state) {
  const {
    page,
    searchText,
    trackInventory,
    orderBy,
    order,
    filterCategories,
    filterTags
  } = state;
  var queryString = "";

  const categoriesString = filterCategories ? filterCategories.join(",") : null;
  const tagsString = filterTags ? filterTags.join(",") : null;

  // Search
  if (searchText) {
    queryString += (queryString !== "" ? "&q=" : "?q=") + searchText;
  }

  // Track Inventory
  if (trackInventory) {
    queryString += (queryString !== "" ? "&" : "?") + "trackInventory=true";
  }

  // Page
  if (page) {
    queryString += (queryString !== "" ? "&" : "?") + "page=" + page;
  }

  // Order by
  if (orderBy) {
    queryString += (queryString !== "" ? "&" : "?") + "orderBy=" + orderBy;
  }

  // Order descending
  if (order) {
    queryString += (queryString !== "" ? "&" : "?") + "order=" + order;
  }

  // Categories
  if (categoriesString) {
    queryString +=
      (queryString !== "" ? "&" : "?") + "categories=" + categoriesString;
  }

  // Tags
  if (tagsString) {
    queryString += (queryString !== "" ? "&" : "?") + "tags=" + tagsString;
  }

  return queryString;
}

export function parseProductsQuery(query) {
  const parsedQuery = qs.parse(query);

  const page = Number(parsedQuery.page) || 1;
  const searchText = parsedQuery.q || "";
  const trackInventory = parsedQuery.trackInventory === "true" || false;
  const orderBy = parsedQuery.orderBy || "";
  const order = parsedQuery.order || "asc";
  const categories = parsedQuery.categories
    ? parsedQuery.categories.split(",")
    : [];
  const tags = parsedQuery.tags ? parsedQuery.tags.split(",") : [];

  const result = {
    page: page,
    searchText: searchText,
    trackInventory: trackInventory,
    orderBy: orderBy,
    order: order,
    filterCategories: categories,
    filterTags: tags
  };

  return result;
}

// ORDERS

export function generateOrdersQuery(state) {
  const {
    page,
    searchText,
    paid,
    pending,
    finished,
    shipped,
    customer,
    startDate,
    endDate
  } = state;
  var queryString = "";

  // Search
  if (searchText) {
    queryString += (queryString !== "" ? "&q=" : "?q=") + searchText;
  }

  // Page
  if (page) {
    queryString += (queryString !== "" ? "&" : "?") + "page=" + page;
  }

  // Statuses
  if (paid) {
    queryString += (queryString !== "" ? "&" : "?") + "paid=" + paid;
  }

  if (pending) {
    queryString += (queryString !== "" ? "&" : "?") + "pending=" + pending;
  }

  if (finished) {
    queryString += (queryString !== "" ? "&" : "?") + "finished=" + finished;
  }

  if (shipped) {
    queryString += (queryString !== "" ? "&" : "?") + "shipped=" + shipped;
  }

  if (customer) {
    queryString += (queryString !== "" ? "&" : "?") + "customer=" + customer;
  }

  if (startDate) {
    queryString +=
      (queryString !== "" ? "&" : "?") +
      "startDate=" +
      moment(startDate).toString();
  }

  if (endDate) {
    queryString +=
      (queryString !== "" ? "&" : "?") +
      "endDate=" +
      moment(endDate).toString();
  }

  return queryString;
}

export function parseOrdersQuery(query) {
  const parsedQuery = qs.parse(query);
  const page = Number(parsedQuery.page) || 1;
  const searchText = parsedQuery.q || "";
  const paid = parsedQuery.paid || false;
  const pending = parsedQuery.pending || false;
  const finished = parsedQuery.finished || false;
  const shipped = parsedQuery.shipped || false;

  const result = {
    page: page,
    searchText: searchText,
    paid: paid,
    pending: pending,
    finished: finished,
    shipped: shipped
  };

  return result;
}

// UNIVERSAL

export function generateBasicQuery(state) {
  const { page, searchText, orderBy, order } = state;
  var queryString = "";

  // Search
  if (searchText) {
    queryString += (queryString !== "" ? "&q=" : "?q=") + searchText;
  }

  // Page
  if (page) {
    queryString += (queryString !== "" ? "&" : "?") + "page=" + page;
  }

  // Order by
  if (orderBy) {
    queryString += (queryString !== "" ? "&" : "?") + "orderBy=" + orderBy;
  }

  // Order descending
  if (order) {
    queryString += (queryString !== "" ? "&" : "?") + "order=" + order;
  }

  return queryString;
}

export function parseBasicQuery(query) {
  const params = new URLSearchParams(query);
  let page = Number(params.get("page")) || 1;
  let searchText = params.get("q") || "";
  let orderBy = params.get("orderBy") || "";
  let order = params.get("order") || "asc";

  const result = {
    page: page,
    searchText: searchText,
    orderBy: orderBy,
    order: order
  };

  return result;
}

/*
 ** LOCAL STORAGE
 */

export function saveLoginData(token, user) {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
}

export function getToken() {
  return localStorage.getItem("token");
}

export function getUser() {
  return JSON.parse(localStorage.getItem("user"));
}

export function isUserTokenValid() {
  const user = getUser();
  const token = getToken();
  const decodedToken = decodeToken();

  const expirationTimestamp = decodedToken ? decodedToken.exp || 0 : 0;
  const currentTimestamp = Math.floor(Date.now() / 1000);

  return (
    token !== null && user !== null && currentTimestamp < expirationTimestamp
  );
}

export function removeLoginData() {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
}

export function decodeToken() {
  const token = localStorage.getItem("token");
  if (token) {
    return jwtDecode(token);
  }

  return null;
}

export function makeFakeId() {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}
