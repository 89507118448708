import localization from "../../utils/localization";

export default function moduleReducer(
  state = {
    fetching: false,
    fetched: false,
    saving: false,
    saved: false,
    modules: [
      [
        {
          _id: "dashboardId",
          to: "/admin/dashboard",
          title: localization.sidebar.home,
          icon: "fas fa-home",
          level: 5000,
        },
        {
          _id: "orderdId",
          to: "/admin/orders",
          title: localization.sidebar.orders,
          icon: "far fa-clipboard",
          level: 5000,
        },
        {
          _id: "productsId",
          to: "/admin/products",
          title: localization.sidebar.products,
          icon: "fas fa-box-open",
          level: 5000,
          subitems: [
            {
              to: "/admin/products/categories",
              title: localization.sidebar.categories,
              icon: "fas fa-archive",
              level: 5000,
            },
            // {
            //   to: "/admin/products/categories-saop",
            //   title: "Kategorije (SAOP)",
            //   icon: "fas fa-archive",
            //   level: 5000,
            // },
            // {
            //   to: "/admin/products/filters",
            //   title: localization.sidebar.filters,
            //   icon: "fas fa-filter",
            //   level: 5000
            // },
            {
              to: "/admin/products/tags",
              title: localization.sidebar.tags,
              icon: "fas fa-tags",
              level: 5000,
            },
          ],
        },
        {
          _id: "customersId",
          to: "/admin/customers",
          title: localization.sidebar.customers,
          icon: "fas fa-briefcase",
          level: 5000,
        },
        {
          _id: "usersId",
          to: "/admin/shop-users",
          title: localization.sidebar.shopUsers,
          icon: "fas fa-user",
          level: 5000,
        },
        {
          _id: "assets",
          to: "/admin/assets",
          title: localization.sidebar.assets,
          icon: "far fa-images",
          level: 5000,
        },
        {
          _id: "store",
          to: "/admin/store",
          title: localization.sidebar.shop,
          icon: "fas fa-store",
          level: 5000,
          subitems: [
            {
              _id: "menu",
              to: "/admin/store/menu",
              title: localization.sidebar.menu,
              icon: "fas fa-random",
              level: 5000,
            },
            {
              _id: "slider",
              to: "/admin/store/sliders",
              title: localization.sidebar.sliders,
              icon: "far fa-clone",
              level: 5000,
            },
            {
              _id: "featured",
              to: "/admin/store/featured",
              title: localization.sidebar.featured,
              icon: "far fa-star",
              level: 5000,
            },
            {
              _id: "files",
              to: "/admin/store/files",
              title: localization.sidebar.files,
              icon: "far fa-folder-open",
              level: 5000,
            },
            {
              _id: "renewalAndProduction",
              to: "/admin/store/renewal-production",
              title: "Obnova in izdelava baterij",
              icon: "fas fa-car-battery",
              level: 5000,
            },
            {
              _id: "priceLists",
              to: "/admin/store/price-lists",
              title: "Ceniki",
              icon: "fas fa-euro-sign",
              level: 5000,
            },
          ],
        },
      ],
    ],
  },
  action
) {
  switch (action.type) {
    case "GET_MODULES_START": {
      return {
        ...state,
        fetched: true,
        fetching: false,
      };
    }

    case "SAVE_MODULE_START":
    case "SAVE_MODULES_START": {
      return {
        ...state,
        modules: action.modules,
      };
    }

    default:
      return state;
  }
}
