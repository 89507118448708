export default function productReducer(
  state = {
    promptActive: false,
    count: 0,
    product: null,
    products: [],
    fetchingProducts: false,
    fetchedProducts: false,
    fetchingProduct: false,
    fetchedProduct: false,
    error: null
  },
  action
) {
  switch (action.type) {
    /* SET PROMPT */
    case "SET_PROMPT_ACTIVE": {
      return {
        ...state,
        promptActive: action.promptActive
      };
    }

    /* COUNT */

    case "GET_PRODUCTS_COUNT_START": {
      return {
        ...state,
        count: 0
      };
    }

    case "GET_PRODUCTS_COUNT_FULFILLED": {
      return {
        ...state,
        count: action.count
      };
    }

    case "GET_PRODUCTS_COUNT_REJECTED": {
      return {
        ...state,
        count: 0,
        error: action.error
      };
    }

    /* GET START */

    case "GET_PRODUCT_START": {
      return {
        ...state,
        fetchingProduct: true,
        fetchedProduct: false,
        product: null
      };
    }

    case "GET_PRODUCTS_START": {
      return {
        ...state,
        fetchingProducts: true,
        fetchedProducts: false,
        product: null,
        error: null
      };
    }

    /* GET FULFILLED */

    case "GET_PRODUCT_FULFILLED": {
      return {
        ...state,
        product: action.product,
        fetchingProduct: false,
        fetchedProduct: true
      };
    }

    case "GET_PRODUCTS_FULFILLED": {
      return {
        ...state,
        products: action.products,
        fetchingProducts: false,
        fetchedProducts: true,
        error: null
      };
    }

    /* GET REJECTED */

    case "GET_PRODUCT_REJECTED": {
      return {
        ...state,
        product: null,
        fetchingProduct: false,
        fetchedProduct: true
      };
    }

    case "GET_PRODUCTS_REJECTED": {
      return {
        ...state,
        products: [],
        fetchingProducts: false,
        fetchedProducts: true,
        error: action.error
      };
    }

    /* UPDATE */

    case "UPDATE_PRODUCT_START": {
      return {
        ...state,
        product: null,
        fetchingProduct: true,
        fetchedProduct: false,
        error: null
      };
    }

    case "UPDATE_PRODUCT_FULFILLED": {
      return {
        ...state,
        product: action.product,
        fetchingProduct: false,
        fetchedProduct: true,
        error: null
      };
    }

    case "UPDATE_PRODUCT_REJECTED": {
      return {
        ...state,
        product: null,
        fetchingProduct: false,
        fetchedProduct: false,
        error: action.error
      };
    }

    case "CREATE_PRODUCT_START":
    case "DELETE_PRODUCT_START":
      return {
        ...state,
        fetchingProduct: true,
        fetchedProduct: false
      };

    case "CREATE_PRODUCT_FULFILLED":
    case "DELETE_PRODUCT_FULFILLED":
      return {
        ...state,
        fetchingProduct: false,
        fetchedProduct: true
      };

    case "CREATE_PRODUCT_REJECTED":
    case "DELETE_PRODUCT_REJECTED":
      return {
        ...state,
        fetchingProduct: false,
        fetchedProduct: false
      };

    default:
      return state;
  }
}
