export default function vatReducer(
  state = {
    vat: null,
    vats: [],
    fetching: false,
    fetched: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case "GET_VATS_START":
    case "GET_VAT_START": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      };
    }

    case "GET_VATS_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        vats: action.vats
      };
    }
    case "GET_VAT_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        vat: action.vat
      };
    }

    case "GET_VATS_REJECTED": {
      return {
        ...state,
        vats: [],
        fetching: false,
        fetched: false,
        error: action.error
      };
    }
    case "GET_VAT_REJECTED": {
      return {
        ...state,
        vat: null,
        fetching: false,
        fetched: false,
        error: action.error
      };
    }

    default:
      return state;
  }
}
