import React from "react";
import Loadable from "react-loadable";

export function Loading(props) {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    return <div>Loading...</div>;
  } else {
    return null;
  }
}

export const Login = Loadable({
  loader: () => import("../pages/external/login/login"),
  loading: Loading,
});

export const Framework = Loadable({
  loader: () => import("../pages/framework"),
  loading: Loading,
});

export const Dashboard = Loadable({
  loader: () => import("../pages/internal/dashboard/dashboard"),
  loading: Loading,
});

export const Orders = Loadable({
  loader: () => import("../pages/internal/orders/orders"),
  loading: Loading,
});

export const OrderDetails = Loadable({
  loader: () => import("../pages/internal/orders/order-details/order-details"),
  loading: Loading,
});

export const Products = Loadable({
  loader: () => import("../pages/internal/products/products"),
  loading: Loading,
});

export const ProductDetails = Loadable({
  loader: () =>
    import("../pages/internal/products/product-details/product-details"),
  loading: Loading,
});

export const Inventories = Loadable({
  loader: () => import("../pages/internal/inventories/inventories"),
  loading: Loading,
});

export const Categories = Loadable({
  loader: () => import("../pages/internal/categories/categories"),
  loading: Loading,
});

export const CategoryDetails = Loadable({
  loader: () => import("../pages/internal/categories/category-details"),
  loading: Loading,
});

export const Tags = Loadable({
  loader: () => import("../pages/internal/tags/tags"),
  loading: Loading,
});

export const Filters = Loadable({
  loader: () => import("../pages/internal/filters/filters"),
  loading: Loading,
});

export const FilterDetails = Loadable({
  loader: () =>
    import("../pages/internal/filters/filter-details/filter-details"),
  loading: Loading,
});

export const Customers = Loadable({
  loader: () => import("../pages/internal/customers/customers"),
  loading: Loading,
});

export const CustomerDetails = Loadable({
  loader: () =>
    import("../pages/internal/customers/customer-details/customer-details"),
  loading: Loading,
});

export const ShopUsers = Loadable({
  loader: () => import("../pages/internal/shop-users/shop-users"),
  loading: Loading,
});

export const ShopUserDetails = Loadable({
  loader: () =>
    import("../pages/internal/shop-users/shop-user-details/shop-user-details"),
  loading: Loading,
});

export const Assets = Loadable({
  loader: () => import("../pages/internal/assets/assets"),
  loading: Loading,
});

export const AssetDetails = Loadable({
  loader: () => import("../pages/internal/assets/asset-details/asset-details"),
  loading: Loading,
});

export const Menu = Loadable({
  loader: () => import("../pages/internal/menu/Menu"),
  loading: Loading,
});

export const Featured = Loadable({
  loader: () => import("../pages/internal/featured/Featured"),
  loading: Loading,
});

export const FeaturedDetails = Loadable({
  loader: () =>
    import("../pages/internal/featured/featured-details/FeaturedDetails"),
  loading: Loading,
});

export const Sliders = Loadable({
  loader: () => import("../pages/internal/sliders/Sliders"),
  loading: Loading,
});

export const SliderDetails = Loadable({
  loader: () =>
    import("../pages/internal/sliders/slider-details/SliderDetails"),
  loading: Loading,
});

export const Files = Loadable({
  loader: () => import("../pages/internal/files/files"),
  loading: Loading,
});

export const Search = Loadable({
  loader: () => import("../pages/internal/search/search"),
  loading: Loading,
});

export const Settings = Loadable({
  loader: () => import("../pages/internal/settings/settings"),
  loading: Loading,
});

export const SettingGeneral = Loadable({
  loader: () => import("../pages/internal/settings/general/general"),
  loading: Loading,
});

export const SettingVats = Loadable({
  loader: () => import("../pages/internal/settings/vats/vats"),
  loading: Loading,
});

export const SettingShipping = Loadable({
  loader: () => import("../pages/internal/settings/shipping/shipping"),
  loading: Loading,
});

export const SettingAccounts = Loadable({
  loader: () => import("../pages/internal/settings/accounts/accounts"),
  loading: Loading,
});

export const AccountDetails = Loadable({
  loader: () =>
    import(
      "../pages/internal/settings/accounts/account-details/account-details"
    ),
  loading: Loading,
});

export const SettingSync = Loadable({
  loader: () => import("../pages/internal/settings/sync/sync"),
  loading: Loading,
});

export const SettingModules = Loadable({
  loader: () => import("../pages/internal/settings/modules/modules"),
  loading: Loading,
});

export const SettingRoles = Loadable({
  loader: () => import("../pages/internal/settings/roles/roles"),
  loading: Loading,
});

export const SettingEmail = Loadable({
  loader: () => import("../pages/internal/settings/email/email"),
  loading: Loading,
});

export const RenewalAndProduction = Loadable({
  loader: () =>
    import("../pages/internal/renewalAndProduction/RenewalAndProduction"),
  loading: Loading,
});

export const PriceLists = Loadable({
  loader: () => import("../pages/internal/priceLists/PriceLists"),
  loading: Loading,
});
