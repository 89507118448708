export default function roleReducer(
  state = {
    fetching: false,
    fetched: false,
    role: null,
    roles: [],
    error: null
  },
  action
) {
  switch (action.type) {
    case "GET_ROLE_START": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        role: null,
        error: null
      };
    }

    case "GET_ROLES_START": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        roles: [],
        error: null
      };
    }

    case "GET_ROLE_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        role: action.role,
        error: null
      };
    }

    case "GET_ROLES_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        roles: action.roles,
        error: null
      };
    }

    case "GET_ROLE_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        role: null,
        error: action.error
      };
    }

    case "GET_ROLES_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        roles: [],
        error: action.error
      };
    }

    default: {
      return state;
    }
  }
}
