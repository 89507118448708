import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  sl: {
    global: {
      noResults: "Ni rezultatov",
    },
    login: {
      title: "Prijava",
      username: "Uporabnik",
      password: "Geslo",
      repeatPassword: "Ponovi geslo",
      login: "Prijavi se",
      forgotPassword: "Pozabljeno geslo",
      resetTitle: "Ponastavi geslo",
      setNewPassword: "Nastavi novo geslo",
      email: "Elektronska pošta",
      resetPassword: "Ponastavi",
      saveNewPassword: "Nastavi novo geslo",
      passwordLinkExpired:
        "URL za ponastavitev gesla neveljaven oz ne obstaja več.",
      toast: {
        emailSuccess:
          "Email za ponastavitev vašega gesla poslan na vpisano elektronsko pošto.",
        dataMissing: "Prosimo vpišite vaš elektronski naslov.",
        passwordChanged: "Geslo spremenjeno!",
        passwordsMustMatch: "Gesli morata biti enaki!",
      },
    },
    header: {
      searchInput: "Išči ...",
      myProfile: "Moj profil",
      logout: "Odjava",
    },
    sidebar: {
      home: "Domov",
      orders: "Naročila",
      products: "Izdelki",
      categories: "Kategorije",
      filters: "Filtri",
      tags: "Oznake",
      customers: "Stranke",
      shopUsers: "Uporabniki trgovine",
      assets: "Slike",
      files: "Gradiva",
      viewShop: "Odpri trgovino",
      settings: "Nastavitve",
      shop: "Nastavitve trgovine",
      menu: "Menu",
      sliders: "Sliders",
      featured: "Izpostavljeno",
    },
    breadcrumbs: {
      admin: "Admin",
      dashboard: "Domov",
      orders: "Naročila",
      products: "Izdelki",
      categories: "Kategorije",
      tags: "Oznake",
      filters: "Filtri",
      customers: "Stranke",
      assets: "Slike",
      settings: "Nastavitve",
      general: "Splošno",
      vat: "Davki",
      shipping: "Poštnine",
      account: "Uporabniški račun",
      profile: "Uporabnik",
      sync: "Sinhronizacija",
      email: "Elektronska pošta",
      store: "Nastavitve trgovine",
      menu: "Menu",
      sliders: "Sliders",
      featured: "Izpostavljeno",
      files: "Katalogi, gradiva",
      add: "Novo",
      edit: "Uredi",
      search: "Iskanje",
    },
    search: {
      title: "Iskalni rezultati za",
      products: "Izdelki",
      customers: "Stranke",
      seeAll: "Poglej vse",
    },
    dashboard: {
      title: "Nadzorna plošča",
      pendingOrders: "naročil v čakanju",
      productsOutOfStock: "produktov ni na zalogi",
    },
    orders: {
      title: "Naročila",
      show: "Prikaži",
      hide: "Skrij",
      filters: "filtre",
      createOrder: "Ustvari",
      created: "Ustvarjeno",
      customer: "Stranka",
      submittedBy: "Oddal",
      paid: "Plačano",
      pending: "V čakanju",
      finished: "Zaključeno",
      shipped: "Poslano",
      saopSync: "SAOP",
    },
    orderFilters: {
      customer: "Stranka",
      paid: "Plačano",
      pending: "V čakanju",
      finished: "Zaključeno",
      shipped: "Poslano",
      from: "Od",
      to: "Do",
      resetFilters: "Ponastavi",
    },
    order: {
      new: "Novo naročilo",
      edit: "Uredi naročilo",
      orderDetails: "Podrobnosti naročila",
      searchForProducts: "Išči med izdelki",
      browse: "Išči",
      rebate: "Rabat",
      shipping: "Dostava",
      notes: "Opombe",
      subtotal: "Košarica",
      total: "Skupaj",
      invoice: "Račun",
      viewInvoice: "Predogled računa",
      paid: "Plačano",
      markAsPaid: "Označi kot plačano",
      markAsUnpaid: "Označi kot neplačano",
      pending: "V čakanju",
      markAsPending: "Označi kot v čakanju",
      markAsNotPending: "Odstrani iz čakanja",
      finished: "Zaključeno",
      markAsFinished: "Označi kot zaključeno",
      markAsNotFinished: "Označi kot nezaključeno",
      shipped: "Poslano",
      markAsShipped: "Označi kot poslano",
      markAsNotShipped: "Označi kot neposlano",
      submittedBy: "Oddal",
      shippingAddress: "Naslov za dostavo",
      billingAddress: "Naslov za plačilo",
      saveOrder: "Shrani naročilo",
      updateOrder: "Posodobi naročilo",
      removeOrder: "Izbriši naročilo",
      editAddress: "Uredi",
      alert: {
        removeTitle: "Izbriši naročilo",
        removeMessage: "Ali ste prepričani, da želite izbrisati naročilo?",
        close: "Zapri",
        remove: "Izbriši",
      },
    },
    products: {
      title: "Izdelki",
      placeholder: "Išči ...",
      show: "Prikaži",
      hide: "Skrij",
      filters: "filtre",
      createProduct: "Ustvari",
      deleteSelected: "Odstrani izbrane",
      choose: "Izberi",
      chooseClose: "Končaj izbiro",
      name: "Naziv",
      exposed: "Izpostavljen",
      ignoreStock: "Ignoriraj zalogo",
      priceVisibleWithoutLogin: "Cena vidna brez prijave",
      price: "Cena",
      stock: "Zaloga",
      isBrandNew: "Novo",
      isOnsale: "Akcija",
      isDiscount: "Popust",
      isOutlet: "Outlet",
      noResults: "Ni rezultatov",
      alert: {
        removeTitle: "Izbriši izdelke",
        removeMessage:
          "Ali ste prepričani, da želite izbrisati izbrane izdelke?",
        remove: "Izbriši",
      },
    },
    productFilters: {
      categories: "Kategorije",
      tags: "Oznake",
      selectedCategories: "Izbrane kategorije",
      selectedTags: "Izbrane Oznake",
    },
    product: {
      new: "Nov izdelek",
      edit: "Uredi izdelek",
      viewInShop: "Odpri v trgovini",
      title: "Naziv",
      keywords: "Keywords",
      keywordsHint: "Iskanje izdelka po naslednjih besedah:",
      description: "Opis",
      shortDescription: "Kratek opis",
      images: "Slike",
      chooseExisting: "Izberi obstoječo",
      upload: "Naloži",
      pricing: "Cenitev",
      price: "Cena",
      vat: "Davek",
      codes: "Šifre",
      sku: "Šifra",
      barcode: "Bar koda (ISBN, UPC, GTIN, ...)",
      stockTitle: "Zaloge",
      warehouse: "Skladišče",
      stock: "Zaloga",
      noStock: "Ni podatka o zalogi",
      dropzonePlaceholder:
        "Za nalaganje lahko tukaj kliknete oz. odvržete datoteke.",
      status: "Stanje",
      exposed: "Izpostavljen",
      isAvailableB2C: "Objavljen v B2C",
      available: "Objavljen v B2B",
      availableHint: "Objavljen v trgovini",
      exposedHint: "Izpostavljen v trgovini",
      saveProduct: "Shrani izdelek",
      updateProduct: "Posodobi izdelek",
      removeProduct: "Izbriši izdelek",
      alert: {
        removeTitle: "Izbriši izdelek",
        removeMessage: "Ali ste prepričani, da želite izbrisati izdelek?",
        close: "Zapri",
        remove: "Izbriši",
      },
    },
    categories: {
      title: "Kategorije",
      addCategory: "Ustvari",
      searchPlaceholder: "Išči...",
      name: "Naziv",
      noCategories: "Ni rezultatov",
      alert: {
        removeTitle: "Izbriši kategorijo",
        removeMessage: "Ali ste prepričani, da želite izbrisati kategorijo?",
        remove: "Izbriši",
      },
    },
    categoryModal: {
      add: "Nova kategorija",
      edit: "Uredi kategorijo",
      image: "Slika",
      title: "Naziv",
      close: "Zapri",
      save: "Shrani",
      filtersTitle:
        "Izberite, po katerih filtrih bodo lahko uporabniki filtrirali v kategoriji:",
      noResults: "Ni izbranih filtrov",
    },
    tags: {
      title: "Oznake",
      addTag: "Ustvari",
      searchPlaceholder: "Išči...",
      name: "Naziv",
      noTags: "Ni rezultatov",
      alert: {
        removeTitle: "Izbriši oznako",
        removeMessage: "Ali ste prepričani, da želite izbrisati oznako?",
        remove: "Izbriši",
      },
    },
    filters: {
      title: "Filtri",
      placeholder: "Išči po filtrih",
      headerTitle: "Urejanje filtrov dodanih v SAOP",
      headerSubtitle: "",
      addTag: "Ustvari",
      code: "",
      name: "SAOP naslov",
      displayText: "Prikazno ime v spletni trgovini",
      options: "Št. podfiltrov",
      noResults: "Ni rezultatov",
      alert: {
        removeTitle: "Izbriši filter",
        removeMessage: "Ali ste prepričani, da želite izbrisati filter?",
        remove: "Izbriši",
      },
    },
    filter: {
      title: "Urejanje filtra",
      update: "Posodobi",
      delete: "Izbriši",
      dataTitle: "Prikaz",
      dataHint: "Nastavitve prikazovanja filtra v spletni trgovini",
      dataWidgetTitle: "Nastavitve",
      code: "Koda iz SAOP",
      name: "Naslov iz SAOP",
      displayText: "Prikaži v spletni trgovini kot",
      noResults: "Ni podfiltrov",
      subfiltersTitle: "Podfiltri",
      subfiltersSubtitle:
        "Pod tem filtrom bodo v spletni trgovini prikazani naslednji podfiltri:",
      alert: {
        removeTitle: "Izbriši filter",
        removeMessage: "Ali ste prepričani, da želite izbrisati filter?",
        remove: "Izbriši",
      },
    },
    fileModal: {
      add: "Novo gradivo",
      edit: "Uredi gradivo",
      remove: "Odstrani datoteko",
      title: "Naziv",
      close: "Zapri",
      save: "Shrani",
    },
    tagModal: {
      add: "Nova oznaka",
      edit: "Uredi oznako",
      title: "Naziv",
      close: "Zapri",
      save: "Shrani",
    },
    addressModal: {
      firstName: "Ime oz. naziv podjetja",
      lastName: "Priimek / prazno",
      address: "Naslov",
      postCode: "Poštna številka",
      city: "Mesto",
      country: "Država",
      close: "Zapri",
      save: "Shrani",
    },
    categoriesWidget: {
      title: "Kategorije",
      addNew: "Dodaj",
      empty:
        "Dodaj izdeleku kategorijo, da ga bo v spletni trgovini lažje najti.",
    },
    tagsWidget: {
      title: "Oznake",
      addNew: "Dodaj",
    },
    customers: {
      title: "Stranke",
      createCustomer: "Ustvari",
      searchPlaceholder: "Išči...",
      name: "Naziv",
      noResults: "Ni rezultatov",
      noCustomer: "(brez stranke)",
    },
    customer: {
      add: "Nova stranka",
      edit: "Uredi stranko",
      title: "Podrobnosti",
      reference: "Šifra",
      taxNumber: "Davčna številka",
      notes: "Opombe",
      blocked: "Blokiran",
      nonPayer: "Neplačnik",
      blockCustomer: "Blokiraj račun",
      unblockCustomer: "Odblokiraj račun",
      markAsPayer: "Označi kot plačnik",
      markAsNonPayer: "Označi kot neplačnik",
      customerOrders: "Naročila stranke",
      date: "Datum",
      numberOfProducts: "Št. izdelkov",
      totalPrice: "Cena",
      submittedBy: "Oddal",
      customerUsers: "Uporabniki",
      createNew: "Ustvari",
      data: "Podatki stranke",
      editData: "Uredi",
      address: "Naslov",
      createCustomer: "Ustvari stranko",
      updateCustomer: "Posodobi stranko",
      removeCustomer: "Izbriši stranko",
      dataMissing: "Manjkajo podatki. Prosimo vnesite podatke stranke.",
      alert: {
        removeTitle: "Izbriši stranko",
        removeMessage: "Ali ste prepričani, da želite izbrisati stranko?",
        remove: "Izbriši",
      },
      hidePrices: "Skrite cene",
      showPrice: "Prikaži cene",
      hidePrice: "Skrij cene",
    },
    customerModal: {
      title: "Podatki stranke",
      name: "Naziv",
      taxNumber: "Davčna številka",
      rebate: "Rabat",
      address: "Naslov",
      postCode: "Poštna številka",
      city: "Mesto",
      country: "Država",
      email: "Elektronska pošta",
      phoneNumbers: "Telefonske številke",
      phone: "Telefon",
    },
    customerWidget: {
      customer: "Stranka",
      remove: "Odstrani",
      taxNumber: "Davčna št.",
      address: "Naslov",
      postCode: "Poštna št.",
      city: "Mesto",
      country: "Država",
      contactInfo: "Kontaktni podatki",
      phone: "Telefon",
      rebate: "Rabat",
      nonPayer: "Stranka ni plačnik!",
      blocked: "Stranka označena kot blokirana!",
    },
    shopUsers: {
      title: "Uporabniki trgovine",
      createUser: "Ustvari",
      searchPlaceholder: "Išči...",
      name: "Ime",
      email: "E-pošta",
      customer: "Stranka",
      salesman: "Potnik",
      status: "Status",
      noResults: "Ni rezultatov",
    },
    shopUser: {
      title: "Uporabnik trgovine",
      add: "Ustvari uporabnika",
      edit: "Shrani uporabnika",
      remove: "Izbriši uporabnika",
      accountInfoTitle: "Podatki uporabnika",
      accountInfoHint: "Prikazni ter prijavni podatki uporabnika",
      passwordTitle: "Geslo",
      passwordHint: "Pošlji email za ponastavitev gesla",
      shopAccessTitle: "Dostop do trgovine",
      shopAccessHint: "Blokiraj / dovoli uporabniku dostop do spletne trgovine",
      salesmanTitle: "Potnik",
      salesmanHint: "Je uporabnik potnik",
      customerTitle: "Matična stranka",
      customerHint: "Nastavi, kateri stranki v osnovi uporabnik pripada",
      customersTitle: "Naročanje za stranke",
      customersHint:
        "Nastavi, za katere stranke poleg matične, lahko uporabnik oddaja naročila v spletni trgovini",
      profile: "Račun",
      firstName: "Ime",
      lastName: "Priimek",
      email: "E-pošta",
      password: "Geslo",
      sendPasswordResetEmail: "Pošlji email za ponastavitev",
      block: "Onemogoči dostop",
      unblock: "Omogoči dostop",
      customer: "Stranka",
      salesmanYes: "Odstrani status potnika",
      salesmanNo: "Nastavi status potnika",
      noSelectedCustomer: "Ni izbrane stranke",
      alert: {
        removeTitle: "Izbriši uporabnika",
        removeMessage: "Ali ste prepričani, da želite izbrisati uporabnika?",
        remove: "Izbriši",
      },
    },
    assets: {
      title: "Slike",
      selectAssets: "Izberi",
      cancelSelection: "Končaj izbiro",
      deleteSelected: "Izbriši izbrane",
      uploadImages: "Naloži",
      noResults: "Ni rezultatov",
      alert: {
        removeTitle: "Izbriši slike",
        removeMessage: "Ali ste prepričani, da želite izbrisati izbrane slike?",
        remove: "Izbriši",
      },
    },
    asset: {
      add: "Nova slika",
      edit: "Uredi sliko",
      save: "Naloži",
      update: "Posodobi",
      delete: "Izbriši",
      finalImage: "Končna slika",
      imageTitle: "Naslov slike",
      imageDescription: "Opis slike",
      currentImageTitle: "Trenutna slika",
      currentImageHint: "",
      selectImageTitle: "Izberite sliko za nalaganje",
      selectImageHint: "Kliknite spodaj ali pa povlecite željene slike",
      changeImage: "Zamenjajte sliko",
      dropzonePlaceholder:
        "Za nalaganje lahko tukaj kliknete oz. odvržete datoteke.",
      create: "Ustvari",
      remove: "Izbriši",
      alert: {
        removeTitle: "Izbriši sliko",
        removeMessage: "Ali ste prepričani, da želite izbrisati sliko?",
        remove: "Izbriši",
      },
    },
    assetsModal: {
      title: "Izberi sliko",
      choose: "Izberi",
    },
    settings: {
      title: "Nastavitve",
      generalTitle: "Splošno",
      generalDescription: "Nastavitve admin panela in trgovine",
      vatTitle: "Davki",
      vatDescription: "Davčne stopnje",
      shippingTitle: "Poštnine",
      shippingDescription: "Vrste poštnin",
      accountsTitle: "Uporabniški računi",
      accountsDescription: "Uporabniški računi za dostop do admin panela",
      syncTitle: "Sinhronizacija",
      syncDescription: "Nastavitve za sinhronizacijo z zunanjimi servisi",
      emailTitle: "Elektronska pošta",
      emailDescription: "Nastavitve elektronske pošte",
      rolesTitle: "Pravice računov",
      rolesDescription: "Nastavljanje pravic",
      b2bInfo: "B2B Informacije",
      version: "Verzija",
    },
    general: {
      title: "Splošne nastavitve",
      saveChanges: "Shrani",
      storeDetailsTitle: "Nastavitve trgovine",
      storeDetailsDesc: "Splošne informacije spletne trgovine.",
      contactInfoTitle: "Kontaktne informacije",
      contactInfoDesc:
        "Podatki bodo prikazani v spletni trgovini kot pomoč uporabnikom",
      invoiceTitle: "Podatki računa",
      invoiceDesc: "Te informacije so prikazane na računih",
      storeName: "Ime trgovine",
      storeDesc: "Opis trgovine",
      storeUrl: "Povezava do trgovine",
      adminPanelUrl: "Povezava do admin panel",
      currency: "Valuta",
      companyName: "Ime podjetja",
      address: "Naslov",
      postCode: "Poštna številka",
      city: "Mesto",
      country: "Država",
      phone: "Telefonska številka",
      emailAddress: "Elektronska pošta",
      bankName: "Ime banke",
      bankAccount: "Bančni račun",
      taxNumber: "Davčna številka",
      fax: "Fax",
      contactEmail: "Email, na katerega boste kontaktirani preko forme",
    },
    vats: {
      title: "Davki",
      type: "Tip",
      value: "Vrednost",
      noResults: "Ni rezultatov",
    },
    shipping: {
      title: "Poštnine",
      infoTitle: "Vrste poštnin pri naročilih",
      infoDesc: "Poštnine bodo na voljo za izbor pred zaključkom naročila",
      addShipping: "Dodaj novo",
      noResults: "Ni rezultatov",
      alert: {
        removeTitle: "Izbriši poštnino",
        removeMessage: "Ali ste prepričani, da želite izbrisati poštnino?",
        remove: "Izbriši",
      },
    },
    shippingModal: {
      title: "Poštnina",
      name: "Naziv",
      price: "Cena",
      description: "Opis",
      close: "Zapri",
      save: "Shrani",
    },
    accounts: {
      title: "Uporabniški računi",
      createNew: "Ustvari",
      infoTitle: "Računi in pravice",
      infoDesc: "Urejanje uporabnikov in njihovih pravic",
      noResults: "Ni rezultatov",
      currentUser: "Trenutni uporabnik",
      otherUsers: "Ostali uporabniki",
      noCurrentUser: "Ni trenutnega uporabnika",
    },
    profile: {
      title: "Uporabnik",
      add: "Ustvari uporabnika",
      edit: "Posodobi uporabnika",
      remove: "Izbriši uporabnika",
      accountInfoTitle: "Podatki uporabnika",
      accountInfoHint: "Prikazni ter prijavni podatki uporabnika",
      passwordTitle: "Geslo",
      passwordHint: "Pošlji email za ponastavitev gesla",
      accountRoleTitle: "Vrsta računa",
      accountRoleHint: "Izberi vrsto računa",
      profile: "Račun",
      firstName: "Ime",
      lastName: "Priimek",
      email: "E-pošta",
      password: "Geslo",
      sendPasswordResetEmail: "Pošlji email za ponastavitev",
      role: "Vrsta",
      alert: {
        removeTitle: "Izbriši uporabnika",
        removeMessage: "Ali ste prepričani, da želite izbrisati uporabnika?",
        remove: "Izbriši",
      },
    },
    sync: {
      title: "Sinhronizacija",
      scheduleTitle: "Načrtovano izvajanje",
      scheduleDesc: "Nastavi interval za avtomatsko načrtovano sinhronizacijo",
      saopTitle: "SAOP",
      saopDesc: "Podatki za povezavo s SAOP strežnikom",
      eventTitle: "Dogodki",
      eventDesc: "Zadnji dogodki",
      interval: "Interval",
      minute: "Minuta",
      hour: "Ura",
      dayOfMonth: "Dan v mesecu",
      mese: "Mesec",
      year: "Leto",
      username: "Uporabniško ime",
      password: "Geslo",
      organizationId: "Organization ID",
      url: "URL",
      noLogs: "Ni dogodkov",
    },
    email: {
      title: "Elektronska pošta",
      reset: "Resetiraj",
      update: "Posodobi",
      providerTitle: "Ponudnik",
      providerDesc: "Izberite ponudnika za pošiljanje elektronske pošte",
      provider: "Ponudnik",
      gmailTitle: "Gmail status",
      gmailDesc:
        "Preverite trenuten status. V primeru napačnih nastavitev, bo potrebno dovoliti dostop do Gmail računa in prilepiti kodo.",
      status: "Status",
      smtpTitle: "SMTP status",
      smptDesc:
        "Preverite trenuten status. Pozor, pred preverjanjem morajo biti prijavni podatki izpolnjeni!",
      username: "E-naslov",
      password: "Geslo",
      checkStatus: "Preveri status",
      statusHint:
        "Podatki izpolnjeni. Če so vpisani podatki pravilni, pošiljanje emailov avtomatsko deluje. Za dodatno testiranje lahko uporabite spodnji obrazec.",
      statusEmpty:
        "Prosimo vnesite vse podatke in kliknite na 'Preveri status'",
      testEmailTitle: "Test email",
      testEmailDesc: "Uporabite obrazec za testiranje pošiljanja emailov",
      enterEmail: "E-pošta prejemnika",
      send: "Pošlji testni email",
    },
    roles: {},
    menu: {
      title: "Menu",
      save: "Shrani",
      hint1: "Upravljanje menuja spletne trgovine",
      hint2:
        "Kategorije, ki bodo tukaj dodane, se bodo pojavile v menu-ju spletne trgovine poleg 'Domov' in 'Izdelki'.",
      categories: "Kategorije",
      menu: "Menu",
      noResults: "Ni dodanih kategorij",
    },
    featured: {
      title: "Izpostavljeno",
      create: "Ustvari",
      hint1: "Upravljanje izpostavljenih kategorij v spletni trgovini.",
      hint2:
        "Izpostavljene kategorije so vidne na prvi strani spletne trgovine.",
      noResults: "Ni izpostavljenih kategorij",
      alert: {
        removeTitle: "Izbriši izpostavljeno kategorijo",
        removeMessage:
          "Ali ste prepričani, da želite izbrisati izpostavljeno kategorijo?",
        remove: "Izbriši",
      },
    },
    featuredDetails: {
      addTitle: "Ustvari izpostavljeno",
      editTitle: "Uredi izpostavljeno",
      add: "Shrani",
      update: "Posodobi",
      remove: "Izbriši",
      description: "Opis",
      button: "Tekst gumba",
      category: "Izpostavljena kategorija",
      preview: "Predogled",
    },
    sliders: {
      title: "Sliders",
      create: "Ustvari",
      hint1: "Upravljanje sliderjev v spletni trgovini.",
      hint2: "Sliderji so vidni na prvi strani spletne trgovine.",
      noResults: "Ni dodanih sliderjev",
      alert: {
        removeTitle: "Izbriši slider",
        removeMessage: "Ali ste prepričani, da želite izbrisati slider?",
        remove: "Izbriši",
      },
    },
    sliderDetails: {
      addTitle: "Ustvari slider",
      editTitle: "Uredi slider",
      add: "Shrani",
      update: "Posodobi",
      remove: "Izbriši",
      description: "Opis",
      smallTitle: "Podnaslov",
      title: "Naslov",
      image: "Slika",
      chooseExisting: "Izberi obstoječo",
      upload: "Naloži",
      preview: "Predogled",
    },
    files: {
      title: "Katalogi, gradiva",
      add: "Ustvari",
      searchPlaceholder: "Išči...",
      name: "Naziv",
      noResults: "Ni rezultatov",
      alert: {
        removeTitle: "Izbriši gradivo",
        removeMessage: "Ali ste prepričani, da želite izbrisati gradivo?",
        remove: "Izbriši",
      },
    },
    modal: {
      close: "Zapri",
      save: "Shrani",
    },
    imagesModal: {
      title: "Izberi slike",
      save: "Shrani",
      close: "Zapri",
      noResults: "Ni rezultatov",
      showMore: "Prikaži več",
    },
    orderModal: {
      title: "Išči izdelke",
      placeholder: "Išči ...",
      selected: "Izbranih",
      products: "izdelkov",
      close: "Zapri",
      save: "Shrani",
    },
    pdf: {
      order: "Naročilo #:",
      created: "Ustvarjeno:",
      bank: "Banka:",
      bankAccount: "Bančni račun:",
      reference: "Referenca:",
      billTo: "Naslov za račun",
      shipTo: "Naslov za dostavo",
      item: "Izdelek",
      quantity: "Kolicina",
      price: "Cena",
      totalPrice: "Skupaj",
      subtotal: "Cena:",
      shipping: "Poštnina:",
      total: "Skupaj",
      notes: "Opombe:",
      selectedShipping: "Izbrana poštnina",
      customer: "Stranka",
    },
    toast: {
      success: "Uspešno!",
      error: "Napaka!",
      globalSuccess: {
        passwordResetEmail: "Email za ponastavitev gesla poslan!",
      },
      globalErrors: {
        insertRequiredData: "Vnesite potrebne podatke",
        insertAllData: "Prosimo vnesite vse podatke",
        customerRole: "Ni bilo mogoče prebrati pravic uporabnika",
        passwordResetEmail:
          "Emaila za ponastavitev gesla ni bilo mogoče poslati",
        noEmailAddress: "Uporabnik brez elektronske pošte!",
      },
      categories: {
        errorReading: "Napaka pri branju kategorij",
        successCreated: "Nova kategorija ustvarjena!",
        errorCreating: "Napaka pri ustvarjanju kategorije",
        successUpdated: "Kategorija posodobljena!",
        errorUpdating: "Napaka pri shranjevanju kategorije",
        successRemoved: "Kategorija izbrisana!",
        errorRemoving: "Napaka pri brisanju kategorije",
      },
      tags: {
        errorReading: "Napaka pri branju oznak",
        successCreated: "Nova oznaka ustvarjena!",
        errorCreating: "Napaka pri ustvarjanju oznake",
        successUpdated: "Oznaka posodobljena!",
        errorUpdating: "Napaka pri shranjevanju oznake",
        successRemoved: "Oznaka izbrisana!",
        errorRemoving: "Napaka pri brisanju oznake",
      },
      filters: {
        errorReading: "Napaka pri branju filtrov",
        successCreated: "Nov filter ustvarjen!",
        errorCreating: "Napaka pri ustvarjanju filtra",
        successUpdated: "Filter posodobljen!",
        errorUpdating: "Napaka pri shranjevanju filtra",
        successRemoved: "Filter izbrisan!",
        errorRemoving: "Napaka pri brisanju filtra",
      },
      customers: {
        errorReading: "Napaka pri branju strank",
        successCreated: "Nova stranka ustvarjena!",
        errorCreating: "Napaka pri ustvarjanju stranke",
        successUpdated: "Stranka posodobljena!",
        errorUpdating: "Napaka pri shranjevanju stranke",
        successRemoved: "Stranka izbrisana!",
        errorRemoving: "Napaka pri brisanju stranke",
      },
      shopUsers: {
        errorReading: "Napaka pri branju uporabnikov",
        successCreated: "Nov uporabnik ustvarjen!",
        errorCreating: "Napaka pri ustvarjanju uporabnika",
        successUpdated: "Uporabnik posodobljen!",
        errorUpdating: "Napaka pri shranjevanju uporabnika",
        successRemoved: "Uporabnik izbrisan!",
        errorRemoving: "Napaka pri brisanju uporabnika",
      },
      orders: {
        errorReading: "Napaka pri branju naročil",
        successCreated: "Novo naročilo ustvarjeno!",
        errorCreating: "Napaka pri ustvarjanju naročila",
        successUpdated: "Naročilo posodobljeno!",
        errorUpdating: "Napaka pri shranjevanju naročila",
        successRemoved: "Naročilo izbrisano!",
        errorRemoving: "Napaka pri brisanju naročila",
      },
      products: {
        errorReading: "Napaka pri branju izdelkov",
        successCreated: "Nov izdelek ustvarjen!",
        errorCreating: "Napaka pri ustvarjanju izdelka",
        successUpdated: "Izdelek posodobljen!",
        errorUpdating: "Napaka pri shranjevanju izdelka",
        successRemoved: "Izdelek izbrisan!",
        errorRemoving: "Napaka pri brisanju izdelka",
      },
      assets: {
        errorReading: "Napaka pri branju slik",
        successCreated: "Nova slika naložena!",
        errorCreating: "Napaka pri nalaganju slike",
        successUpdated: "Slika posodobljena!",
        errorUpdating: "Napaka pri shranjevanju slike",
        successRemoved: "Uspešno izbrisano!",
        errorRemoving: "Napaka pri brisanju slike",
      },
      settings: {
        errorReading: "Napaka pri branju nastavitev",
        successUpdated: "Nastavitve posodobljene!",
        errorUpdating: "Napaka pri posodabljanju nastavitev",
      },
      vat: {
        errorReading: "Napaka pri branju davkov",
      },
      shipping: {
        errorReading: "Napaka pri branju poštnin",
        successCreated: "Nova poštnina ustvarjena!",
        errorCreating: "Napaka pri ustvarjanju poštnine",
        successUpdated: "Poštnina posodobljena!",
        errorUpdating: "Napaka pri shranjevanju poštnine",
        successRemoved: "Poštnina izbrisana!",
        errorRemoving: "Napaka pri brisanju poštnine",
      },
      email: {
        successEmailSent: "Testni email poslan!",
        errorEmailSent:
          "Napaka pri pošiljanju testnega emaila. Preverite nastavitve.",
        errorReading: "Napaka pri branju nastavitev",
        successUpdated: "Nastavitve posodobljene!",
        errorUpdating: "Napaka pri shranjevanju nastavitev",
        recepientAddress: "Vnesite prejeemnika",
      },
      menu: {
        errorReading: "Napaka pri branju menu-ja",
        successCreated: "Nov menu ustvarjen!",
        errorCreating: "Napaka pri ustvarjanju menu-ja",
        successRemoved: "Menu izbrisan!",
        errorRemoving: "Napaka pri brisanju menu-ja",
      },
      featured: {
        errorReading: "Napaka pri branju izpostavljenih kategorij",
        successCreated: "Izpostavljena kategorija dodana!",
        errorCreating: "Napaka pri ustvarjanju izpostavljenih kategorij",
        successUpdated: "Izpostavljena kategorija posodobljena!",
        errorUpdating: "Napaka pri shranjevanju izpostavljene kategorije",
        successRemoved: "Izpostavljena kategorija izbrisana!",
        errorRemoving: "Napaka pri brisanju izpostavljene kategorije",
      },
      sliders: {
        errorReading: "Napaka pri branju sliderjev",
        successCreated: "Slider ustvarjen!",
        errorCreating: "Napaka pri ustvarjanju sliderja",
        successUpdated: "Slider posodobljen!",
        errorUpdating: "Napaka pri shranjevanju sliderja",
        successRemoved: "Slider izbrisan!",
        errorRemoving: "Napaka pri brisanju sliderja",
      },
      files: {
        errorReading: "Napaka pri branju gradiv",
        successCreated: "Novo gradivo ustvarjeno!",
        errorCreating: "Napaka pri ustvarjanju gradiva",
        successUpdated: "Gradivo posodobljeno!",
        errorUpdating: "Napaka pri shranjevanju gradiva",
        successRemoved: "Gradivo izbrisano!",
        errorRemoving: "Napaka pri brisanju gradiva",
      },
    },
    prompt: {
      header: "Neshranjene spremembe",
      message:
        "Stran ima neshranjene spremembe. Ali ste prepričani, da jo želite zapustiti?",
      leave: "Zapusti",
    },
    apiErrors: {
      notAuthenticated: "Seja je potekla.",
      networkError: "Napaka pri povezavi",
      noServerResponse: "Ni odgovora s strežnika",
      notAllowed: "Dostop zavrnjen",
    },
  },
});
