const INITIAL_STATE = {
  count: 0,
  order: null,
  pendingOrders: [],
  orders: [],
  fetchingPendingOrders: false,
  fetchedPendingOrders: false,
  fetchingOrders: false,
  fetchedOrders: false,
  fetchingOrder: false,
  fetchedOrder: false,
  ordersError: null,
  orderError: null,
};

const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ORDERS_COUNT_FULFILLED": {
      return {
        ...state,
        count: action.count,
      };
    }

    case "GET_ORDERS_COUNT_REJECTED": {
      return {
        ...state,
        count: 0,
      };
    }

    /*
     **  GET ORDERS START
     */

    case "GET_ORDER_START": {
      return {
        ...state,
        order: null,
        fetchingOrder: true,
        fetchedOrder: false,
        orderError: null,
      };
    }
    case "GET_PENDING_ORDERS_START": {
      return {
        ...state,
        fetchingPendingOrders: true,
        fetchedPendingOrders: false,
      };
    }
    case "GET_ORDERS_START":
    case "GET_CUSTOMER_ORDERS_START": {
      return {
        ...state,
        fetchingOrders: true,
        fetchedOrders: false,
        ordersError: null,
      };
    }

    /*
     **  GET ORDERS FULFILLED
     */

    case "GET_ORDER_FULFILLED": {
      return {
        ...state,
        order: action.order,
        fetchingOrder: false,
        fetchedOrder: true,
        orderError: null,
      };
    }
    case "GET_PENDING_ORDERS_FULFILLED": {
      return {
        ...state,
        pendingOrders: action.orders,
        fetchingPendingOrders: false,
        fetchedPendingOrders: true,
      };
    }
    case "GET_ORDERS_FULFILLED":
    case "GET_CUSTOMER_ORDERS_FULFILLED": {
      return {
        ...state,
        orders: action.orders,
        fetchingOrders: false,
        fetchedOrders: true,
        ordersError: null,
      };
    }

    /*
     **  GET ORDERS REJECTED
     */

    case "GET_ORDER_REJECTED": {
      return {
        ...state,
        order: null,
        fetchingOrder: false,
        fetchedOrder: true,
        orderError: action.error,
      };
    }
    case "GET_PENDING_ORDERS_REJECTED": {
      return {
        ...state,
        pendingOrders: [],
        fetchingPendingOrders: false,
        fetchedPendingOrders: true,
      };
    }
    case "GET_ORDERS_REJECTED":
    case "GET_CUSTOMER_ORDERS_REJECTED": {
      return {
        ...state,
        orders: [],
        fetchingOrders: false,
        fetchedOrders: true,
        ordersError: action.error,
      };
    }

    case "UPDATE_ORDER_START":
      return {
        ...state,
        fetchingOrder: true,
        fetchedOrder: false,
      };

    case "UPDATE_ORDER_SAOPSYNC_FULFILLED":
      return {
        ...state,
        order: action.order,
        fetchingOrder: false,
        fetchedOrder: true,
      };

    case "UPDATE_ORDER_FULFILLED":
      return {
        ...state,
        fetchingOrder: false,
        fetchedOrder: true,
      };

    case "UPDATE_ORDER_REJECTED":
      return {
        ...state,
        fetchingOrder: false,
        fetchedOrder: false,
      };

    case "RESET_ORDER":
      return {
        ...state,
        fetchingOrder: false,
        fetchedOrder: false,
        order: null,
      };

    default:
      return state;
  }
};

export default orderReducer;
