import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import * as Utils from "./utils";

LogRocket.init("apto/andeco-b2b-be");
setupLogRocketReact(LogRocket);

const user = Utils.getUser();
if (user) {
  LogRocket.identify(user._id, {
    name: `${user.first} ${user.last}`,
    email: user.email,

    // Add your own custom user variables here, ie:
    subscriptionType: user.role.title,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
