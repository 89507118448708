export default function promptReducer(
  state = {
    visible: false
  },
  action
) {
  switch (action.type) {
    case "SET_PROMPT_START": {
      return {
        ...state,
        visible: action.visible
      };
    }

    default:
      return state;
  }
}
