export default function moduleReducer(
  state = {
    fetching: false,
    fetched: false,
    fetchingLogs: false,
    fetchedLogs: false,
    saving: false,
    saved: false,
    settings: null,
    logs: null,

    fetchingEmail: false,
    fetchedEmail: false,
    email: null,
    emailError: null,

    checkingGmail: false,
    checkedGmail: false,
    gmailSet: false,
    gmailUrl: null,
    gmailError: null
  },
  action
) {
  switch (action.type) {
    /* EMAIL */
    case "GET_EMAIL_SETTINGS_START": {
      return {
        ...state,
        fetchingEmail: true,
        fetchedEmail: false,
        email: null,
        emailError: null
      };
    }
    case "GET_EMAIL_SETTINGS_FULFILLED": {
      return {
        ...state,
        fetchingEmail: false,
        fetchedEmail: true,
        email: action.email,
        emailError: null
      };
    }
    case "GET_EMAIL_SETTINGS_REJECTED": {
      return {
        ...state,
        fetchingEmail: false,
        fetchedEmail: false,
        email: null,
        emailError: action.error
      };
    }

    case "CHECK_GMAIL_STATUS_START": {
      return {
        ...state,
        checkingGmail: true,
        checkedGmail: false,
        gmailSet: false,
        gmailUrl: null,
        gmailError: null
      };
    }
    case "CHECK_GMAIL_STATUS_FULFILLED": {
      return {
        ...state,
        checkingGmail: false,
        checkedGmail: true,
        gmailSet: action.gmailSet,
        gmailUrl: null,
        gmailError: null
      };
    }
    case "CHECK_GMAIL_STATUS_REJECTED": {
      return {
        ...state,
        checkingGmail: false,
        checkedGmail: false,
        gmailSet: false,
        gmailUrl: action.gmailUrl,
        gmailError: action.error
      };
    }

    /* LOGS */

    case "GET_LOGS_START": {
      return {
        ...state,
        fetchingLogs: true,
        fetchedLogs: false,
        logs: null
      };
    }

    case "GET_LOGS_FULFILLED": {
      return {
        ...state,
        fetchingLogs: false,
        fetchedLogs: true,
        logs: action.logs
      };
    }

    case "GET_LOGS_REJECTED": {
      return {
        ...state,
        fetchingLogs: false,
        fetchedLogs: false,
        logs: null
      };
    }

    case "GET_SETTINGS_START": {
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    }

    case "GET_SETTINGS_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        settings: action.settings
      };
    }

    case "GET_SETTINGS_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.error
      };
    }

    default:
      return state;
  }
}
