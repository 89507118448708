export default function tagReducer(
  state = {
    count: 0,
    tags: [],
    fetching: false,
    fetched: false,
    error: null
  },
  action
) {
  switch (action.type) {
    // TAGS COUNT
    case "GET_TAGS_COUNT_FULFILLED": {
      return {
        ...state,
        count: action.count
      };
    }

    case "GET_TAGS_COUNT_REJECTED": {
      return {
        ...state,
        count: 0
      };
    }

    case "GET_TAGS_START":
    case "GET_TAG_START":
    case "SAVE_TAG_START":
    case "REMOVE_TAG_START": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      };
    }
    case "SAVE_TAG_FULFILLED":
    case "REMOVE_TAG_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null
      };
    }
    case "GET_TAGS_FULFILLED":
    case "GET_TAG_FULFILLED": {
      return {
        ...state,
        tags: action.tags,
        fetching: false,
        fetched: true,
        error: null
      };
    }
    case "GET_TAGS_REJECTED":
    case "GET_TAG_REJECTED":
    case "SAVE_TAG_REJECTED":
    case "REMOVE_TAG_REJECTED": {
      return {
        ...state,
        tags: [],
        fetching: false,
        fetched: true,
        error: action.error
      };
    }
    default:
      return state;
  }
}
