import React from "react";
import { Router, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

import { AnimatedSwitch } from "react-router-transition";
import ReduxToastr from "react-redux-toastr";
import { Framework, Login } from "./utils/loadables";

import store from "./store";
import history from "./history";

import "react-redux-toastr/src/styles/index.scss";
import "bootstrap/dist/css/bootstrap-grid.css";
import "./scss/main.scss";

const routes = [
  {
    path: "/login",
    component: Login
  },
  {
    path: "/admin/",
    component: Framework
  }
];

function App() {
  return (
    <Provider store={store}>
      <div>
        <ReduxToastr
          timeOut={2500}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={false}
        />
        <Router history={history}>
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 2 }}
            atActive={{ opacity: 1 }}
            mapStyles={styles => {
              if (styles.opacity > 1) {
                return { display: "none" };
              }
              return { opacity: styles.opacity };
            }}
          >
            {routes.map((route, i) => (
              <Route key={i} path={route.path} component={route.component} />
            ))}
            <Redirect from="/" to="/login" />
          </AnimatedSwitch>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
