const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  menu: []
};

const menuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_MENU_START":
      return {
        ...state,
        fetching: true,
        fetched: false,
        menu: []
      };

    case "GET_MENU_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        menu: action.menu
      };

    case "GET_MENU_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false,
        menu: []
      };

    case "CREATE_MENU_START":
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case "CREATE_MENU_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true
      };

    case "CREATE_MENU_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false
      };

    case "DELETE_MENU_START":
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case "DELETE_MENU_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true
      };

    case "DELETE_MENU_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false
      };

    default:
      return state;
  }
};

export default menuReducer;
