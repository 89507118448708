const INITIAL_STATE = {
  count: 0,
  filters: [],
  filter: null,
  fetching: false,
  fetched: false,
  error: null
};

const filterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_FILTER_START":
      return {
        ...state,
        filter: null,
        fetching: true,
        fetched: false,
        error: null
      };

    case "GET_FILTERS_START":
      return {
        ...state,
        filter: null,
        filters: [],
        fetching: true,
        fetched: false,
        error: null
      };

    case "UPDATE_FILTER_START":
    case "UPDATE_SUBFILTER_START":
    case "REMOVE_FILTER_START": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      };
    }
    case "UPDATE_FILTER_FULFILLED":
    case "UPDATE_SUBFILTER_FULFILLED":
    case "REMOVE_FILTER_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null
      };
    }
    case "GET_FILTERS_FULFILLED":
      return {
        ...state,
        filters: action.filters,
        count: action.totalElements || state.totalElements,
        fetching: false,
        fetched: true,
        error: null
      };

    case "GET_FILTER_FULFILLED":
      return {
        ...state,
        filter: action.filter,
        fetching: false,
        fetched: true,
        error: null
      };

    case "GET_FILTERS_REJECTED":
    case "GET_FILTER_REJECTED":
    case "UPDATE_SUBFILTER_REJECTED":
    case "UPDATE_FILTER_REJECTED":
    case "REMOVE_FILTER_REJECTED": {
      return {
        ...state,
        filters: [],
        fetching: false,
        fetched: true,
        error: action.error
      };
    }

    case "RESET_FILTER":
      return {
        ...state,
        filter: null
      };

    default:
      return state;
  }
};

export default filterReducer;
