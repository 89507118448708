export default function fileReducer(
  state = {
    count: 0,
    files: [],
    fetching: false,
    fetched: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case "GET_FILES_COUNT_FULFILLED": {
      return {
        ...state,
        count: action.count
      };
    }

    case "GET_FILES_COUNT_REJECTED": {
      return {
        ...state,
        count: 0
      };
    }

    case "GET_FILES_START":
      return {
        ...state,
        fetching: true,
        fetched: false,
        files: []
      };

    case "GET_FILES_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        files: action.files
      };

    case "GET_FILES_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false,
        files: []
      };

    case "CREATE_FILE_START":
    case "UPDATE_FILE_START":
    case "DELETE_FILE_START":
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case "CREATE_FILE_FULFILLED":
    case "UPDATE_FILE_FULFILLED":
    case "DELETE_FILE_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true
      };

    case "CREATE_FILE_REJECTED":
    case "UPDATE_FILE_REJECTED":
    case "DELETE_FILE_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false
      };

    default:
      return state;
  }
}
