import { Types } from "../actions/renewalProductionActions";

const INITIAL_STATE = {
  count: 0,
  files: [],
  fetching: false,
  fetched: false,
  error: false,
};

const renewalProductionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.GET_FILES_COUNT_FULFILLED: {
      return {
        ...state,
        count: action.count,
      };
    }
    case Types.GET_FILES_COUNT_REJECTED:
      return {
        ...state,
        count: 0,
      };
    case Types.GET_FILES_START:
      return {
        ...state,
        fetching: true,
        fetched: false,
        files: [],
      };
    case Types.GET_FILES_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        files: action.files,
      };
    case Types.GET_FILES_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        files: [],
      };
    case Types.CREATE_FILE_START:
    case Types.UPDATE_FILE_START:
    case Types.DELETE_FILE_START:
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    case Types.CREATE_FILE_FULFILLED:
    case Types.UPDATE_FILE_FULFILLED:
    case Types.DELETE_FILE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    case Types.CREATE_FILE_REJECTED:
    case Types.UPDATE_FILE_REJECTED:
    case Types.DELETE_FILE_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
      };

    default:
      return state;
  }
};

export default renewalProductionReducer;
