export default function userReducer(
  state = {
    currentUser: null,
    user: null,
    users: [],
    fetching: false,
    fetched: false,
    error: null
  },
  action
) {
  switch (action.type) {
    /* RESET */
    case "RESET_USERS": {
      return {
        ...state,
        users: []
      };
    }

    case "RESET_CURRENT_USER": {
      return {
        ...state,
        user: null
      };
    }

    /* START */

    case "GET_SHOP_USERS_START":
    case "GET_USERS_START":
    case "GET_USER_START":
    case "GET_CURRENT_USER_START": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      };
    }

    /* FULFILLED */
    case "GET_SHOP_USERS_FULFILLED":
    case "GET_USERS_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        users: action.users,
        error: null
      };
    }

    case "GET_USER_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        user: action.user,
        error: null
      };
    }

    case "GET_CURRENT_USER_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        currentUser: action.currentUser,
        error: null
      };
    }

    /* REJECTED */
    case "GET_SHOP_USERS_REJECTED":
    case "GET_USERS_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        users: [],
        error: action.error
      };
    }

    case "GET_USER_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        user: null,
        error: action.error
      };
    }

    case "GET_CURRENT_USER_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        currentUser: null,
        error: action.error
      };
    }

    case "UPDATE_USER_START":
    case "DELETE_USER_START":
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case "UPDATE_USER_FULFILLED":
    case "DELETE_USER_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true
      };

    case "UPDATE_USER_REJECTED":
    case "DELETE_USER_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false
      };

    default:
      return state;
  }
}
