export default function customerReducer(
  state = {
    count: 0,
    customer: null,
    customers: [],
    fetching: false,
    fetched: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case "RESET_CUSTOMER": {
      return {
        ...state,
        customer: null
      };
    }

    case "UPDATE_CUSTOMER_BLOCK_STATUS_START":
    case "UPDATE_CUSTOMER_START":
    case "GET_CUSTOMERS_START":
    case "GET_CUSTOMER_START": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      };
    }
    case "UPDATE_CUSTOMER_BLOCK_STATUS_FULFILLED":
    case "UPDATE_CUSTOMER_FULFILLED": {
      return {
        ...state,
        customer: action.customer,
        fetching: false,
        fetched: true,
        error: null
      };
    }
    case "GET_CUSTOMER_FULFILLED": {
      return {
        ...state,
        customer: action.customer,
        fetching: false,
        fetched: true,
        error: null
      };
    }
    case "GET_CUSTOMERS_FULFILLED": {
      return {
        ...state,
        customers: action.customers,
        fetching: false,
        fetched: true,
        error: null
      };
    }
    case "UPDATE_CUSTOMER_BLOCK_STATUS_REJECTED":
    case "UPDATE_CUSTOMER_REJECTED":
    case "GET_CUSTOMERS_REJECTED":
    case "GET_CUSTOMER_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: action.error
      };
    }

    case "GET_CUSTOMERS_COUNT_START": {
      return {
        ...state,
        count: 0
      };
    }

    case "GET_CUSTOMERS_COUNT_FULFILLED": {
      return {
        ...state,
        count: action.count
      };
    }

    case "GET_CUSTOMERS_COUNT_REJECTED": {
      return {
        ...state,
        error: action.error
      };
    }

    default:
      return state;
  }
}
