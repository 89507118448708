const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  sliders: [],
  slider: null
};

const sliderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_SLIDERS_START":
      return {
        ...state,
        fetching: true,
        fetched: false,
        sliders: [],
        slider: null
      };

    case "GET_SLIDERS_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        sliders: action.sliders
      };

    case "GET_SLIDERS_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false,
        sliders: []
      };

    case "GET_SLIDER_START":
      return {
        ...state,
        fetching: true,
        fetched: false,
        slider: null
      };

    case "GET_SLIDER_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        slider: action.slider
      };

    case "GET_SLIDER_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false,
        feature: null
      };

    case "CREATE_SLIDER_START":
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case "CREATE_SLIDER_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true
      };

    case "CREATE_SLIDER_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false
      };

    case "DELETE_SLIDER_START":
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case "DELETE_SLIDER_FULFILLED":
      return {
        ...state,
        fetching: false,
        fetched: true,
        slider: null
      };

    case "DELETE_SLIDER_REJECTED":
      return {
        ...state,
        fetching: false,
        fetched: false
      };

    case "RESET_SLIDER":
      return {
        ...state,
        slider: null
      };

    default:
      return state;
  }
};

export default sliderReducer;
