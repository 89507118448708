import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";

import categoryReducer from "./categoryReducer";
import customerReducer from "./customerReducer";
import featuredReducer from "./featuredReducer";
import fileReducer from "./fileReducer";
import filterReducer from "./filterReducer";
import headerReducer from "./headerReducer";
import imageReducer from "./imageReducer";
import menuReducer from "./menuReducer";
import moduleReducer from "./moduleReducer";
import orderReducer from "./orderReducer";
import priceListsReducer from "./priceListsReducer";
import productReducer from "./productReducer";
import promptReducer from "./promptReducer";
import renewalProductionReducer from "./renewalProductionReducer";
import roleReducer from "./roleReducer";
import settingReducer from "./settingReducer";
import shippingReducer from "./shippingReducer";
import sliderReducer from "./sliderReducer";
import tagReducer from "./tagReducer";
import userReducer from "./userReducer";
import vatReducer from "./vatReducer";

export default combineReducers({
  categoryReducer,
  featuredReducer,
  fileReducer,
  filterReducer,
  toastr: toastrReducer,
  headerReducer,
  productReducer,
  tagReducer,
  customerReducer,
  orderReducer,
  userReducer,
  menuReducer,
  moduleReducer,
  imageReducer,
  priceListsReducer,
  promptReducer,
  renewalProductionReducer,
  roleReducer,
  settingReducer,
  shippingReducer,
  sliderReducer,
  vatReducer,
});
