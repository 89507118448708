export default function imageReducer(
  state = {
    count: 0,
    image: null,
    images: [],
    fetching: false,
    fetched: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case "CREATE_IMAGE_START":
    case "UPDATE_IMAGE_START":
    case "DELETE_IMAGE_START": {
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    }

    case "CREATE_IMAGE_FULFILLED":
    case "UPDATE_IMAGE_FULFILLED":
    case "DELETE_IMAGE_FULFILLED": {
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    }

    case "CREATE_IMAGE_REJECTED":
    case "UPDATE_IMAGE_REJECTED":
    case "DELETE_IMAGE_REJECTED": {
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    }

    case "GET_IMAGES_START": {
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    }

    case "GET_IMAGES_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        images: action.images
      };
    }

    case "GET_IMAGES_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        images: [],
        error: action.error
      };
    }

    case "GET_IMAGE_START": {
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    }

    case "GET_IMAGE_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        image: action.image
      };
    }

    case "GET_IMAGE_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        image: null,
        error: action.error
      };
    }

    case "GET_IMAGES_COUNT_START": {
      return {
        ...state
      };
    }

    case "GET_IMAGES_COUNT_FULFILLED": {
      return {
        ...state,
        count: action.count
      };
    }

    case "GET_IMAGES_COUNT_REJECTED": {
      return {
        ...state,
        count: 0
      };
    }

    case "RESET_IMAGE": {
      return {
        ...state,
        image: null
      };
    }

    default:
      return state;
  }
}
